//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import ButtonsTray from "../common/ButtonsTray.vue";
export default {
    name: "FirstScreenNew",
    components: { ButtonsTray },
    data() {
        return {
            openModal: false,
        };
    },
    computed: {
        ...mapGetters(["getSize", "options"]),
        classList() {
            return [
                this.propsData.widget_type,
                this.propsData.image && this.propsData.with_image !== "0"
                    ? "hasImg"
                    : "",
            ];
        },
        bgImg() {
            if (this.propsData.with_image === "0" || this.getSize !== "mobile")
                return null;
            if (window.innerWidth >= 769 && this.propsData.image) {
                return {
                    backgroundImage: `url('${this.path(
                        this.propsData.image
                    )}')`,
                };
            } else if (window.innerWidth <= 769 && this.propsData.image_mob) {
                return {
                    backgroundImage: `url('${this.path(
                        this.propsData.image_mob
                    )}')`,
                };
            } else {
                return null;
            }
        },
        phone() {
            const phone = this.options.contacts[0]?.phones[0]?.number;
            return phone ? `tel:${phone}` : null;
        },
        tg() {
            return this.options.contacts[0]?.messengers?.[0]?.link;
        },
        ln() {
            return this.options.contacts[0]?.socials?.[0]?.link;
        },
        email() {
            const email = this.options.contacts?.[0]?.email;
            return email ? `mailto:${email}` : null;
        },
    },
};
